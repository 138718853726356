@import './Variables';
@import './CustomThemes.scss';

:root {
  --primary-color: var(--primary);
  --body_background: #ffffff;
  --body_background_light: #f5f6fa;
  --body_color: #526484;
  --body_text_disabled: #d3d3d3;
  --card_bordered: rgb(219, 233, 234);
  --border-color: #d9d9d9;
  --loading-overlay: rgba(255, 255, 255, 0.7);
  --table_background: #ffffff;
  --table_background_light: #f5f6fa;
  --box_shadow: rgba(43, 55, 72, 0.35);
  --scrollbar_thumb: #c4c4c4;
  --modal_background: #ffffff;
}

[data-theme='dark'] {
  --primary-color: var(--primary);
  --body_background: #101924;
  --body_background_light: #141c26;
  --body_color: #ffffff;
  --body_text_disabled: #343a45;
  --card_bordered: rgb(32, 50, 71);
  --border-color: rgb(32, 50, 71);
  --loading-overlay: rgba(16, 25, 36, 0.7);
  --table_background: #18212d;
  --table_background_light: #141c26;
  --box_shadow: rgba(255, 255, 255, 0.35);
  --scrollbar_thumb: #4b4e52;
  --modal_background: #18212d;
}

/* CUSTOM THEME CONFIGURATIONS */
button,
.btn {
  border-radius: var(--button-radius) !important;
  -webkit-border-radius: var(--button-radius) !important;
  -moz-border-radius: var(--button-radius) !important;
  -ms-border-radius: var(--button-radius) !important;
  -o-border-radius: var(--button-radius) !important;
}

.nk-header-wrap {
  justify-content: var(--header-wrap-justify-content);
}

.nk-header-brand {
  width: var(--header-brand-width);
  height: var(--header-brand-height);
  padding: var(--header-brand-padding);
}

.nk-header-tools {
  margin-left: var(--header-tools-margin-left);
}

.nk-header-menu {
  width: var(--header-menu-width);
  display: var(--header-menu-display);
  justify-content: var(--header-menu-justify-content);

  .nk-menu-text {
    text-transform: var(--header-menu-text-transform);
    font-weight: var(--header-menu-text-weight);
  }
}

.logo-img {
  width: var(--logo-width);
  height: var(--logo-height);
  max-width: var(--logo-max-width);
  max-height: var(--logo-max-height);
}

/* END > CUSTOM THEME CONFIGURATIONS */

.no-wrap {
  white-space: nowrap;
}

.is-disable {
  color: var(--body_text_disabled) !important;
  pointer-events: none !important;
}

.no-cursor-pointer {
  pointer-events: none !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-help {
  cursor: help !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

p.link,
p.nav-link,
.cursor-pointer {
  cursor: pointer;
}

.accordion .btn-link {
  color: #6e82a5;
}

.accordion .btn-link:hover {
  color: var(--primary-light);
}

.btn:focus {
  box-shadow: none;
}

.report-button a {
  color: #fff !important;
}

.button-light {
  color: #526484;
}

.button-light:hover {
  color: #fff !important;
}

.scroll-horizontal {
  overflow-x: auto;
}

.scroll-horizontal-none {
  overflow-x: hidden;
}

.scroll-vertical {
  overflow-y: auto;
}

.scroll-vertical-none {
  overflow-y: hidden;
}

.spinner-grow {
  margin: 0 auto;
}

.wide-xl {
  transition: max-width 0.1s linear;
  -webkit-transition: max-width 0.1s linear;
  -moz-transition: max-width 0.1s linear;
  -ms-transition: max-width 0.1s linear;
  -o-transition: max-width 0.1s linear;
}

.nk-block-embeded {
  max-width: 944px;
}

.wide-xl.app-full-width {
  width: 100% !important;
  max-width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;

  .nk-block-embeded {
    max-width: calc(100vw - 350px) !important;
  }
}

.swal2-timer-progress-bar {
  background-color: rgba(101, 118, 255, 0.7) !important;
}

.mw-none {
  max-width: none !important;
}

#react-select-3-listbox {
  div[id*='-option'] {
    &:hover {
      background-color: var(--primary-light);
      color: #fff;
      cursor: pointer;
    }
  }
}

#society__directors-table {
  .dropleft .dropdown-toggle::before {
    border: none;
  }
}

.z-0,
.z-index-0 {
  z-index: 0 !important;
}

.z-1,
.z-index-1 {
  z-index: 1 !important;
}

.z-10,
.z-index-10 {
  z-index: 10 !important;
}

.z-100,
.z-index-100 {
  z-index: 100 !important;
}

.z-1000,
.z-index-1000 {
  z-index: 1000 !important;
}

.mandatory-field {
  color: $text-danger;
  margin-left: 4px;
}

.table-font-sm {
  font-size: 12.5px !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.text-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-2-lines-ellipsis {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-3-lines-ellipsis {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hover-shadow {
  &:hover {
    -webkit-box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.05);
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border: solid 1px var(--card_bordered);
  background-color: var(--scrollbar_thumb);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

::-webkit-scrollbar-track {
  background: var(--body_background_light);
  border-radius: 6px;
}

/* NOUI Slider */
.noUi-target,
.noUi-tooltip {
  background: var(--primary) !important;
}

#represented-instructions-table {
  font-size: 12px !important;
  text-align: left !important;
  margin-bottom: 40px !important;

  tr td:nth-child(2) {
    font-weight: 600;
  }
}

#section-folders {
  .btn:focus {
    box-shadow: none !important;
  }

  .nk-file-content-type {
    width: 30px;
  }

  span.accordion-head {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  h6 {
    height: 20px;
  }

  .accordion-inner {
    padding-left: 14px;
    padding-right: 14px;

    .nk-menu-sub li {
      padding-left: 10px;
      padding-right: 10px;

      .nk-file-content-type {
        display: flex;
        align-items: center;
      }

      &:hover {
        background-color: var(--lighter);
      }
    }
  }
}

.society-data-wrapper {
  .data-item {
    padding: 0.5rem 1.25rem;

    .data-col.data-col-end {
      width: 50px;
    }

    .data-value {
      font-weight: 500;
    }
  }
}

/* NAV TABS */

@media (max-width: 768px) {
  .nav-tabs.nav-tabs__no-wrap {
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;

    .nav-item {
      display: flex;
      text-align: center;
      padding-right: 0;

      .nav-link {
        display: flex;
        align-self: flex-end;
      }
    }
  }
}

/* BACKGROUNDS */
.page-full-height {
  height: calc(100vh - 75px);
}

.page-full-height__with-header {
  height: calc(100vh - 100px);
}

.custom-page-background {
  background-image: url('https://www.resources.sttok.com/images/login-bg.jpg');
  background-size: cover;
  background-position: top left;
}

.bg-wave {
  background-image: url('../../assets/images/backgrounds/bg-table-wave.svg');
  background-size: cover;
  background-position: top left;
}

/* CUSTOM PAGES */
#councils-list-page {
  .page-header-subtitle:not(:first-child) h3 {
    font-size: 22px;
    margin-bottom: 0;
    margin-top: 10px;
  }
}

/* CUSTOM COMPONENTS */
.excel-cell-numbers {
  // simulate a Excell cell with numbers
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  color: #526484;
  border: solid 1px #d9d9d9;
  background-color: #f5f6fa;
  padding: 5px;
}
